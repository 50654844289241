// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN NOTIFICATION */

.ant-notification {
  position: fixed;
  max-width: calc(100vw - 48px);
}
